import React from "react"
import Layout from "../../components/Layout"
import { graphql } from "gatsby"

import MemberList from "../../components/MemberList"

export default function Staff({ pageContext: { language }, data: { staff } }) {

	return(
		<Layout
      lang={language}
			availableLanguages={["th", "en"]}
      title={"Staff"}
      pathname={"/staff/"}
			customTitle={true}
    >
      <h2>{language === "th" ? "ผู้ทรงคุณวุฒิ" : "Advisors"}</h2>
      <MemberList data={staff.nodes.filter(node => node.frontmatter.jobTitleValue > 9.9)} showOrg={false} showJobTitle={true} lang={language} indexThis={false} />
      <h2>{language === "th" ? "บุคลากร" : "Staff"}</h2>
      <MemberList data={staff.nodes.filter(node => node.frontmatter.jobTitleValue <= 9.9)} showOrg={false} showJobTitle={true} lang={language} indexThis={false} />
		</Layout>
	)

}

export const pageQuery = graphql`
  query StaffQuery($language: String!) {
    staff: allMdx(
      filter: {
        fields: {
          collection: {eq: "members"},
          lang: {eq: $language}
        },
        frontmatter: {
          jobTitleValue: {gte: 0}
        }
      },
      sort: {fields: [frontmatter___jobTitleValue, frontmatter___title], order: [DESC, ASC]}
    ) {
      nodes {
        frontmatter {
          memberid
          title
          jobTitle
          jobTitleValue
          image {
            publicURL
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
        fields {
          originalPath
          lang
        }
      }
    }
  }
`