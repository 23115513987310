import React from "react"
import Author from "../Author"

import classNames from "classnames/bind"
import styles from "./styles.module.scss"

export default function MemberList({ data, showOrg=true, showJobTitle=false, lang="th", indexThis=true }) {

	const cx = classNames.bind(styles);

  return(
    <div className={cx("memberList")}>
    {
      data.map((member, i) =>
        <div className={cx("authorCard")} key={`member${i}`}>
          <Author author={member.frontmatter}
            link={member.fields.originalPath}
            flexible={false}
            typesenseField={"body"}
            showOrg={showOrg}
            showJobTitle={showJobTitle}
            hoverEffect={true}
            lang={lang}
            indexThis={indexThis}
            placeCenter={true}
          />
        </div>
      )
    }
    </div>
  )
}