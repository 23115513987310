import React from "react"
import Link from "../IntlLink"
import { getString, dontBreak } from "../../util"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

import classNames from "classnames/bind"
import styles from "./styles.module.scss"

export default function Author({ author, link, flexible, typesenseField, showOrg=true, showJobTitle=false, hoverEffect=false, lang, name, indexThis=true, placeCenter=false }) {
	const cx = classNames.bind(styles);

	// only name is provided
	if (!author && name) {
		return(
			<div className={cx("card", {"flexible": flexible})}>
				<StaticImage
					src="../../../content/images/placeholder/user.png"
					alt={name}
					layout="constrained"
					width={200}
					height={200}
				/>
				<div className={cx("details")}>
					<div className={cx("name")} {...(indexThis && {'data-typesense-field': typesenseField})}>{dontBreak(name)}</div>
				</div>
			</div>
		)
	}

	return(
		<Link to={!author.exclude && link} className={cx("nolinkcolor", "nohover")}>
			<div className={cx("card", {"flexible": flexible, "center": placeCenter})}>
				{author.image
				? <GatsbyImage image={author.image.childImageSharp.gatsbyImageData} alt={author.title || getString(author.name)} className={cx("pic", {hoverimg: hoverEffect})} loading="eager" />
				: <StaticImage
						src="../../../content/images/placeholder/user.png"
						alt={name}
						layout="constrained"
						width={200}
						height={200}
					/>
				}
				<div className={cx("details")}>
					<div className={cx("name")} {...(indexThis && {'data-typesense-field': typesenseField})}>{dontBreak(author.title || getString(author.name, lang))}</div>
					{showOrg && author.org && <div className={cx("org")}>{getString(author.org.id || author.org, lang)}</div>}
					{showJobTitle && <div className={cx("org")}>{author.jobTitle}</div>}
				</div>
			</div>
		</Link>
	)
}